var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_c("app-header")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-card",
            { staticClass: "container", attrs: { rounded: "xl" } },
            [
              _c("h1", { staticClass: "top-title" }, [_vm._v("Edit profile")]),
              !_vm.isLoading
                ? _c("user-form", {
                    attrs: {
                      user: _vm.user,
                      userSkills: _vm.originSkills,
                      userInterests: _vm.originInterests,
                      userValues: _vm.originValues,
                      edit: true
                    },
                    on: { submit: _vm.submit, cancel: _vm.cancel }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }