<template>
  <layout>
    <template #header>
      <app-header></app-header>
    </template>
    <div class="content">
      <v-card class="container" rounded="xl">
        <h1 class="top-title">Edit profile</h1>
        <user-form
          @submit="submit"
          @cancel="cancel"
          :user="user"
          :userSkills="originSkills"
          :userInterests="originInterests"
          :userValues="originValues"
          v-if="!isLoading"
          :edit="true"
        />
      </v-card>
    </div>
  </layout>
</template>
<script>
import UserForm from "../../users/components/UserForm.vue";
import Header from "../../app/components/headers/DefaultHeader";
import DefaultLayout from "../../app/components/layouts/DefaultLayout";
import handleRequestMixin from "../../app/mixins/handle-request-mixin";
import sendRequest from "../../app/utils/send-request-helper";
import {
  updateUserSkills,
  updateUserInterests,
  updateUserValues,
  updateUserProfile,
} from "./user-profile-edit";

export default {
  name: "UserEdit",
  metaInfo: {
    title: "Edit Profile",
  },
  components: {
    "user-form": UserForm,
    "app-header": Header,
    layout: DefaultLayout,
  },
  mixins: [handleRequestMixin],
  data() {
    return {
      user: {},
      skills: [],
      interests: [],
      values: [],
      isLoading: true,
    };
  },
  async mounted() {
    const userId = this.userId;
    // All of info has to be loading before sending to child component
    await this.getUserProfile(userId);
    await this.getUserSkills(userId);
    await this.getUserValues(userId);
    await this.getUserInterests(userId);
    this.isLoading = false;
  },
  computed: {
    originInterests() {
      return this.interests.map((interest) => interest.value);
    },
    originSkills() {
      return this.skills.map((skill) => skill.value);
    },
    originValues() {
      return this.values.map((value) => value.value);
    },
    userId() {
      return this.$store.state.user.username;
    },
  },
  methods: {
    cancel() {
      const userId = this.$store.state.user.username;
      this.$router.push(`/users/${userId}`);
    },
    async getUserProfile(userId) {
      const getUser = async function () {
        this.user = await sendRequest({
          url: `/resources/users/${userId}`,
          method: "GET",
          isAuth: false,
          requestLabel: "getting user data",
          itemName: "user",
        });
      }.bind(this);
      await this.handleRequestMixin_alertError(getUser);
    },
    async getUserSkills(userId) {
      const getSkills = async function () {
        const response = await sendRequest({
          url: `/relationships/has-skill?id=${userId}`,
          method: "GET",
          isAuth: false,
          requestLabel: "getting user skills",
          itemName: "user skills",
        });
        this.skills = response;
      }.bind(this);
      await this.handleRequestMixin_alertError(getSkills);
    },
    async getUserValues(userId) {
      const getValues = async function () {
        const response = await sendRequest({
          url: `/relationships/has-value?id=${userId}`,
          method: "GET",
          isAuth: false,
          requestLabel: "getting user values",
          itemName: "user value",
        });
        this.values = response;
      }.bind(this);
      await this.handleRequestMixin_alertError(getValues);
    },
    async getUserInterests(userId) {
      const getInterest = async function () {
        const response = await sendRequest({
          url: `/relationships/has-interest?id=${userId}`,
          method: "GET",
          isAuth: false,
          labelRequest: "getting user interests",
          itemName: "user interest",
        });
        this.interests = response;
      }.bind(this);
      await this.handleRequestMixin_alertError(getInterest);
    },
    async submit({ user, skills, values, interests, isDone }) {
      try {
        const userId = this.userId;
        skills &&
          (await updateUserSkills({
            originSkills: this.skills,
            updateSkills: skills,
            userId,
          }));
        interests &&
          (await updateUserInterests({
            originInterests: this.interests,
            updateInterests: interests,
            userId,
          }));
        values &&
          (await updateUserValues({
            originValues: this.values,
            updateValues: values,
            userId,
          }));
        await updateUserProfile(user, userId, this.user.avatar);
        isDone && this.$router.push("/users/" + userId);
      } catch (e) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while editing user"
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 700px;
  width: 60%;
  flex: 1;
  margin: 0 auto;
  margin-top: 15px;
  padding: 25px 40px;
  .top-title {
    color: $inqliGreen;
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .container {
    width: 95%;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
</style>
